var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import FileInputWrapperComponent from '@shared/ui/inputs/FileInputWrapper';
import Button from '@shared/ui/buttons/Button';
import { getAvatarEncodedImages } from '@features/profile/profile-create';
import ImageEditor from '@shared/ui/misc/ImageEditor';
import { StepContainer, Title } from '@widgets/sidebars/shared';
const ImageEditorContainer = styled.div(props => ({
    marginBottom: `${props.theme.spacing_sizes.xs * 2.5}px`,
}));
const FileInputWrapper = styled(FileInputWrapperComponent)(props => ({
    marginBottom: `${props.theme.spacing_sizes.xs * 9.25}px`,
}));
const InnerContainer = styled.div(props => ({
    flex: 8,
    marginBottom: `${props.theme.spacing_sizes.xl}px`,
}));
const EditPhoto = ({ initValues, goToNextStep }) => {
    const { t } = useTranslation();
    const editorRef = useRef(null);
    const fileInputRef = useRef(null);
    const { watch, setValue } = useForm({ defaultValues: initValues });
    const initialImage = watch('initialImage');
    const getImage = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const image = (_a = editorRef.current) === null || _a === void 0 ? void 0 : _a.getImages();
        if (image) {
            const encodedImages = yield getAvatarEncodedImages({
                avatar: image,
                imageFileType: initialImage === null || initialImage === void 0 ? void 0 : initialImage.type,
                imageFileName: (initialImage === null || initialImage === void 0 ? void 0 : initialImage.name) || '',
            });
            if (encodedImages.status === 'success') {
                const [original, avatar, thumbnail, avatarImg] = encodedImages.value;
                setValue('image', {
                    avatar: avatar,
                    original: original,
                    thumbnail: thumbnail,
                    avatarImg: avatarImg,
                });
                goToNextStep({
                    initialImage,
                    image: {
                        avatar: avatar,
                        original: original,
                        thumbnail: thumbnail,
                        avatarImg: avatarImg,
                    },
                });
            }
        }
    });
    const handleFileUpload = (file) => {
        setValue('initialImage', file);
    };
    const handleLinkClick = () => {
        var _a;
        (_a = fileInputRef.current) === null || _a === void 0 ? void 0 : _a.click();
    };
    return (_jsx(StepContainer, { title: (_jsx(Title, { children: t('profile.create_trader.steps.edit_photo.title', { ns: 'common' }) })), content: (_jsx(InnerContainer, { children: initialImage && (_jsx(ImageEditorContainer, { children: _jsx(ImageEditor, { image: initialImage, ref: editorRef, defaultImageZoom: 1.5, minMaxImageZoom: [1, 3], sliderStep: 0.1, cropImageProps: {
                        rotate: 0,
                        width: 350,
                        height: 350,
                        color: [255, 255, 255, 0.6],
                        borderRadius: 350,
                    } }) })) })), buttons: (_jsx(Button, { onClick: getImage, variant: 'filled', color: 'primary', size: 'large', fullWidth: true, children: t('profile.create_trader.steps.edit_photo.btn_text', { ns: 'common' }) })) }));
};
export default EditPhoto;
